import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Link } from 'gatsby';
import { TopNavigationItem } from '@entur/menu';
import { LeadParagraph } from '@entur/typography';
import { UserIcon, StatsIcon, SettingsIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { topnavigation } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<TopNavigationItem></TopNavigationItem>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      TopNavigationItem,
      LeadParagraph,
      UserIcon,
      StatsIcon,
      SettingsIcon,
      Playground,
      PageHeader,
      ImportStatement,
      topnavigation,
      DefaultLayout,
      _frontmatter
    }} props={topnavigation} mdxType="Playground">
  <TopNavigationItem mdxType="TopNavigationItem"></TopNavigationItem>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "topnavigationitem"
    }}>{`TopNavigationItem`}</h3>
    <ImportStatement imports="TopNavigationItem" mdxType="ImportStatement" />
    <Props of={TopNavigationItem} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Top navigation brukes som en global navigasjonsmeny som ligger horisontalt på toppen av en side eller applikasjon.
Sammenlignet med Side navigation, støtter Top navigation kun et nivå av navigasjon.
Hvis du har færre enn 3 menypunkter i navigasjonshierarkiet, er det denne komponenten du bør bruke.
Menypunktene vises direkte og er alltid tilgjengelig i headeren for å hjelpe brukeren å finne det de trenger.
`}<inlineCode parentName="p">{`active`}</inlineCode>{` brukes for å vise hvilken underside man er inne på for øyeblikket, og maks én skal være det til enhver tid.`}</p>
    <p>{`Bruk Tabs hvis brukeren kan bytte visninger i samme kontekst.
Hvis det er mer enn 6 menyelementer, eller hvis du trenger å gruppere elementer, vurder å bruk Side navigation istedenfor.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      